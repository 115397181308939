:root {
  --primary-color: #85c440;
  --secondary-color: #fff;
}
a{
  text-decoration: none !important;
}
.meghauli-nav{
  background: var(--primary-color)!important;
}
.navbar-nav{
  display: flex !important;
    justify-content: flex-end !important;
}
.meghauli-brand{
  height: 70px;
  overflow: hidden;
}
.meghauli-brand img{
  height: 100%;
  object-fit: contain; 
  filter: invert(1) brightness();  
}
.meghauli-menu{
  display: flex;
    justify-content: flex-end;
}
.meghauli-menu ul>li{
  margin-left: 10px;
  margin-right: 10px;
}
.meghauli-menu ul>li>a{
  text-transform: uppercase;
  color: #000;
}
.meghauli-menu .meghauli-menu-btn{
  background: #000;
  border-radius: 100px;
  color: #fff!important;
}
.margin-tb{
  margin-top: 70px;
  margin-bottom: 70px;
}
.home-intro-logo{
  height: 200px;
  overflow: hidden;
}
.home-intro-logo img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.home-intro-text {

}
.home-intro-text h1{
  color: var(--primary-color);
}
.home-intro-text p{
  padding-top: 15px;
  padding-bottom: 15px;
}
.meghauli-btn-1{
  display: inline-block;
}
.meghauli-btn-1 p{
  display: inline-block;
  background: var(--primary-color);
  color: #fff;
  border-radius: 5px;
  border: 2px solid var(--primary-color);
  padding: 7px 35px;
  transition: all 0.5s ease-in-out;
}
.meghauli-btn-1 p:hover{
  background: #fff;
  color: var(--primary-color);
}
.padding-0{
  padding: 0;
}
.home-package-card{
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('./Assests/1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 150px;
  padding-bottom: 100px;
  margin-top: 30px;
}
.home-package-card p{
  color: #fff;
}
.home-package-card h1{
  color: #fff;
}
.home-room-card{
  padding: 55px;
}
.home-room-image{
  height: 500px;
  overflow: hidden;
}
.home-room-image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.home-room-text h1{
  color: var(--primary-color);
}
.home-room-text p{
  text-align: justify;
  padding-top: 30px;
  padding-bottom: 30px;
}
.home-room-card ul{
  padding-left: 0;
}
.home-room-card ul>li{
  display: inline-block;
}
.meghauli-btn-1{
  display: inline-block;
  margin-left: 8px;
}
.meghauli-btn-1 p{
  display: inline-block;
  background: var(--primary-color);
  color: #fff;
  border-radius: 5px;
  border: 2px solid var(--primary-color);
  padding: 7px 35px;
  transition: all 0.5s ease-in-out;
}
.meghauli-btn-1 p:hover{
  background: #fff;
  color: var(--primary-color);
}
.home-activities-title h1{
  text-align: center;
  color: var(--primary-color);
}
.home-activities-item {
  margin: 20px;
}
.home-activities-image {
  height: 200px;
  overflow: hidden;
}
.home-activities-image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.home-reviews-title h1{
  text-align: center;
  color: var(--primary-color);
}
.home-reviews-image {
  margin: 0 auto;
  height: 200px;
  width: 200px;
  overflow: hidden;
  border-radius: 100px;
}
.home-reviews-image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.home-reviews-text h4{
  color: var(--primary-color);
  text-align: center;
}
.home-reviews-text p{
  text-align: center;
}
.footer-bg{
  background: #23221e;
  padding-top: 100px;
}
.footer-text h5{
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  padding-bottom: 30px;
}
.footer-text ul{
  padding-left: 0;
  margin-bottom: 0;
  margin: 0 auto;
}
.footer-text ul>li{
  text-align: center;
}
.footer-text ul>li>a>p{
  color: #fff;
}
.footer-text p{
  color: #fff;
  text-align: center;
}
.footer-copyright{
  padding-top: 50px;
  padding-bottom: 30px;
}
.footer-copyright p{
  text-align: center;
  color: #fff;
}